import React, { Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";
import Loading from "./components/Loading";

const AdminLogin = lazy(() => import("./admin/Login"));
const AdminHome = lazy(() => import("./admin/Home"));
const ManagementLogin = lazy(() => import("./management/Login"));
const ManagementHome = lazy(() => import("./management/Home"));
const ClientHome = lazy(() => import("./client/Home"));
const ClientLogin = lazy(() => import("./client/Login"));
const CustomClientLogin = lazy(() => import("./client/CustomLogin"));
const ConfirmEmail = lazy(() => import("./client/ConfirmEmail"));
const EmailVerificationSent = lazy(
  () => import("./client/EmailVerificationSent")
);
const ChangePassword = lazy(() => import("./client/ChangePassword"));
const ClientResetPasswordConfirmation = lazy(
  () => import("./client/ResetPasswordConfirmation")
);
const FirstTimeLogin = lazy(() => import("./client/FirstTimeLogin"));
const ForgotCompanyID = lazy(() => import("./client/ForgotCompanyID"));
const ResetCompanyID = lazy(() => import("./client/ResetCompanyID"));
const ClientForgotUserID = lazy(() => import("./client/ForgotUserID"));
const ResetUserID = lazy(() => import("./client/ResetUserID"));
const ClientForgotPassword = lazy(() => import("./client/ForgotPassword"));
const EmailOTP = lazy(() => import("./client/EmailOTP"));
const ClientPasswordOTP = lazy(() => import("./client/PasswordOTP"));
const ClientResetPassword = lazy(() => import("./client/ResetPassword"));
const AdminForgotUserID = lazy(() => import("./admin/ForgotUserID"));
const AdminResetUserID = lazy(() => import("./admin/ResetUserID"));
const AdminForgotPassword = lazy(() => import("./admin/ForgotPassword"));
const AdminPasswordOTP = lazy(() => import("./admin/PasswordOTP"));
const AdminResetPassword = lazy(() => import("./admin/ResetPassword"));
const AdminResetPasswordConfirmation = lazy(
  () => import("./admin/ResetPasswordConfirmation")
);

export const GLOBAL_ROUTES: Record<GlobalRouteKeys, AppRoute> = {
  // Admin
  adminLogin: {
    path: "/admin/login",
    exact: true,
    component: AdminLogin,
    name: "Admin - Login",
  },
  adminForgotUserId: {
    path: "/admin/forgot-user-id",
    exact: true,
    component: AdminForgotUserID,
    name: "Admin - Forgot User ID",
  },
  adminResetUserId: {
    path: "/admin/reset-user-id",
    exact: true,
    component: AdminResetUserID,
    name: "Admin - Reset User ID",
  },
  adminForgotPassword: {
    path: "/admin/forgot-password",
    exact: true,
    component: AdminForgotPassword,
    name: "Admin - Forgot Password",
  },
  adminPasswordOtp: {
    path: "/admin/password-otp",
    exact: true,
    component: AdminPasswordOTP,
    name: "Admin - Password OTP",
  },
  adminResetPassword: {
    path: "/admin/reset-password",
    exact: true,
    component: AdminResetPassword,
    name: "Admin - Reset Password",
  },
  adminResetPasswordConfirmation: {
    path: "/admin/reset-password-confirmation",
    exact: true,
    component: AdminResetPasswordConfirmation,
    name: "Admin - Reset Password",
  },
  adminHome: {
    path: "/admin",
    exact: false,
    component: AdminHome,
    name: "Admin - Home",
  },
  // Management
  managementLogin: {
    path: "/management/login",
    exact: true,
    component: ManagementLogin,
    name: "Management - Home",
  },
  managementHome: {
    path: "/management",
    exact: false,
    component: ManagementHome,
    name: "Management - Home",
  },
  // Client
  clientLogin: {
    path: "/login",
    exact: true,
    component: ClientLogin,
    name: "Client - Login",
  },
  // customClientLogin: {
  //   path: "/custom-login",
  //   exact: true,
  //   component: CustomClientLogin,
  //   name: "Custom Client - Login",
  // },

  // clientWALogin: {
  //   path: "/login",
  //   params: ["tenant"],
  //   exact: true,
  //   component: ClientLogin,
  //   name: "Client - Login",
  // },
  clientConfirmInformation: {
    path: "/confirm-information",
    exact: true,
    component: ConfirmEmail,
    name: "Client - Email Confirmation",
  },
  clientEmailVerificationSent: {
    path: "/email-verification-sent",
    exact: true,
    component: EmailVerificationSent,
    name: "Client - Email Confirmation Sent",
  },
  clientChangePassword: {
    path: "/change-password",
    exact: true,
    component: ChangePassword,
    name: "Client - Change Password",
  },
  clientResetPasswordConfirmation: {
    path: "/reset-password-confirmation",
    exact: true,
    component: ClientResetPasswordConfirmation,
    name: "Client - Reset Password",
  },
  clientFirstTimeLogin: {
    path: "/first-time-login",
    exact: true,
    component: FirstTimeLogin,
    name: "Client - First Time Login",
  },
  clientForgotCompanyId: {
    path: "/forgot-company-id",
    exact: true,
    component: ForgotCompanyID,
    name: "Client - Forgot Company ID",
  },
  clientResetCompanyId: {
    path: "/reset-company-id",
    exact: true,
    component: ResetCompanyID,
    name: "Client - Reset Company ID",
  },
  clientForgotUserId: {
    path: "/forgot-user-id",
    exact: true,
    component: ClientForgotUserID,
    name: "Client - Forgot User ID",
  },
  clientResetUserId: {
    path: "/reset-user-id",
    exact: true,
    component: ResetUserID,
    name: "Client - Reset User ID",
  },
  clientEmailOtp: {
    path: "/email-otp",
    exact: true,
    component: EmailOTP,
    name: "Client - Email OTP",
  },
  clientPasswordOtp: {
    path: "/password-otp",
    exact: true,
    component: ClientPasswordOTP,
    name: "Client - Password OTP",
  },
  clientResetPassword: {
    path: "/reset-password",
    exact: true,
    component: ClientResetPassword,
    name: "Client - Reset Password",
  },
  clientForgotPassword: {
    path: "/forgot-password",
    exact: true,
    component: ClientForgotPassword,
    name: "Client - Forgot Password",
  },
  clientHome: {
    path: "",
    exact: false,
    component: ClientHome,
    name: "Client - Home",
  },
};

const getRouteFromAppRoute = (route: AppRoute) => {
  return (
    <Route
      key={route.path}
      {...route}
      component={route.component}
      path={route.path + (route.params ? "/:" + route.params?.join("/:") : "")}
    >
      {route.children
        ? route.children.map((c) => <>{getRouteFromAppRoute(c)}</>)
        : ""}
    </Route>
  );
};

function Router() {
  const topLevelRoutes = Object.values(GLOBAL_ROUTES).map((route) =>
    getRouteFromAppRoute(route)
  );
  return (
    <Suspense
      fallback={
        <div style={{ width: "100vw", height: "100vh" }}>
          <Loading size="large" />
        </div>
      }
    >
      <Switch>{topLevelRoutes}</Switch>
    </Suspense>
  );
}

export default Router;

// Types
export type GlobalRouteKeys =
  | "adminLogin"
  // | "waAdminLogin"
  | "adminForgotUserId"
  | "adminResetUserId"
  | "adminForgotPassword"
  | "adminPasswordOtp"
  | "adminResetPassword"
  | "adminResetPasswordConfirmation"
  | "adminHome"
  // Client
  | "clientLogin"
  // | "customClientLogin"
  // | "clientWALogin"
  | "clientConfirmInformation"
  | "clientEmailVerificationSent"
  | "clientChangePassword"
  | "clientResetPasswordConfirmation"
  | "clientFirstTimeLogin"
  | "clientForgotCompanyId"
  | "clientResetCompanyId"
  | "clientForgotUserId"
  | "clientResetUserId"
  | "clientEmailOtp"
  | "clientPasswordOtp"
  | "clientResetPassword"
  | "clientForgotPassword"
  | "clientHome"
  // Management
  | "managementLogin"
  | "managementHome";
