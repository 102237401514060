import { Auth, INITIAL_AUTH } from "../components/providers/AuthProvider";

const KEY = "carina-app";

class StorageService {
  setInitialEmpty() {
    const user: StorageValue = {
      auth: INITIAL_AUTH,
    };
    localStorage.setItem(KEY, JSON.stringify(user));
    return user;
  }

  getLocalAccessToken() {
    const val = localStorage.getItem(KEY);
    if (val == undefined) return val;
    const user: StorageValue = JSON.parse(val);
    return user?.auth.accessToken;
  }

  getLocalRefreshToken() {
    const val = localStorage.getItem(KEY);
    if (val == undefined) return val;
    const user: StorageValue = JSON.parse(val);
    return user?.auth.refreshToken;
  }

  getAuth() {
    const val = localStorage.getItem(KEY);
    if (val == undefined) return val;
    const user: StorageValue = JSON.parse(val);
    // console.log("--- Inside get auth ---");
    // console.log(user?.auth);
    return user.auth;
  }

  updateLocalAccessToken(token: string) {
    const val = localStorage.getItem(KEY);
    let data: StorageValue;
    if (val == undefined) {
      data = this.setInitialEmpty();
    } else {
      data = JSON.parse(val);
    }
    data.auth.accessToken = token;
    localStorage.setItem(KEY, JSON.stringify(data));
  }

  updateLocalRefreshToken(token: string) {
    const val = localStorage.getItem(KEY);
    let data: StorageValue;
    if (val == undefined) {
      data = this.setInitialEmpty();
    } else {
      data = JSON.parse(val);
    }
    data.auth.refreshToken = token;
    localStorage.setItem(KEY, JSON.stringify(data));
  }

  updateAuth(auth: Partial<Auth>) {
    const val = localStorage.getItem(KEY);
    let data: StorageValue;
    if (val == undefined) {
      data = this.setInitialEmpty();
    } else {
      data = JSON.parse(val);
    }
    data.auth = { ...data.auth, ...auth };
    localStorage.setItem(KEY, JSON.stringify(data));
  }
}

export default new StorageService();

// Types
type StorageValue = {
  auth: Auth;
};
