import Axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import React, { createContext, useEffect, useMemo } from "react";
import { useAuth } from "../hooks/useAuth";
import { INITIAL_AUTH } from "./AuthProvider";

export const AxiosContext = createContext<AxiosInstance | undefined>(undefined);

function AxiosProvider({ children }: React.PropsWithChildren<unknown>) {
  const { auth, setAuth } = useAuth();
  const axios = Axios.create();

  // const interceptors = useMemo(() => {
  //   const req = async (config: AxiosRequestConfig) => {
  //     console.log(`Intercepting request to: ${config.url}`);
  //     console.log("auth:");
  //     console.log(auth);
  //     console.log("Pre interception:");
  //     console.log("headers:");
  //     console.log(config.headers);
  //     const protectionUrlSegment = config.url?.split("/")[5];
  //     console.log(`Protection URL segment: ${protectionUrlSegment}`);
  //     if (protectionUrlSegment === "open") {
  //       // @ts-ignore
  //       config.headers[
  //         "Authorization"
  //       ] = `basic ${process.env.REACT_APP_AUTH_BASIC_TOKEN}`;
  //     } else if (auth.accessToken) {
  //       // @ts-ignore
  //       config.headers["Authorization"] = `${auth.accessToken}`;
  //     }
  //     console.log("Post interception:");
  //     console.log("headers:");
  //     console.log(config.headers);
  //     return config;
  //   };

  //   const res = async (response: AxiosResponse) => {};

  //   const err = async (error: AxiosError) => {
  //     const originalConfig = error.config as AxiosRequestConfig;
  //     const protectionUrlSegment = originalConfig.url?.split("/")[5];
  //     console.log(`Protection URL segment: ${protectionUrlSegment}`);
  //     if (protectionUrlSegment !== "open" && error.response!.status === 401) {
  //       const tenantId = auth.tenantId;
  //       const refreshToken = auth.refreshToken;
  //       if (refreshToken == undefined || tenantId == undefined) {
  //         return error;
  //       }
  //       const refreshRes = await axios.put(
  //         `${process.env.REACT_APP_API_BASE_ENDPOINT}/api/v1/open/refresh`,
  //         {
  //           refreshToken: refreshToken,
  //         },
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `basic ${process.env.REACT_APP_AUTH_BASIC_TOKEN}`,
  //             "X-TenantID": tenantId,
  //           },
  //         },
  //       );
  //       if (refreshRes.status === 200 || refreshRes.status === 201) {
  //         const newAccessToken = `bearer ${refreshRes.data["access_token"]}`;
  //         // TODO: needs fix
  //         console.log("setting auth in axios provider");
  //         console.log("setting auth in axios provider:::step 1");
  //         setAuth({
  //           accessToken: newAccessToken,
  //           refreshToken: refreshRes.data["refresh_token"],
  //         });
  //         console.log("setting auth in axios provider:::step 2");
  //         console.log("ORIGINAL CONFIG");
  //         console.log(originalConfig);
  //         const updatedConfig = { ...originalConfig };

  //         updatedConfig!.headers!["Authorization"] = newAccessToken;
  //         console.log("UPDATED CONFIG");
  //         console.log(updatedConfig);
  //         const res = await Axios.options(updatedConfig.url!, updatedConfig);
  //         return res;
  //         // return error;
  //       } else {
  //         // Refresh token expired
  //         setAuth(INITIAL_AUTH);
  //         return refreshRes;
  //       }
  //     }
  //     return Promise.reject(error);
  //   };

  //   return ({
  //     request: (config: AxiosRequestConfig) => (req(config), config),
  //     response: (response: AxiosResponse) => (res(response), response),
  //     // TODO: might need further fix
  //     // error: (error: AxiosError) => (err(error), Promise.reject(error)),
  //     error: (error: AxiosError) => (err(error)),
  //   });
  // }, [auth]);

  // useEffect(() => {
  //   const reqInterceptor = axios.interceptors.request.use(
  //     interceptors.request,
  //     // interceptors.error,
  //   );
  //   const resInterceptor = axios.interceptors.response.use(
  //     interceptors.request,
  //     interceptors.error,
  //   );

  //   return () => {
  //     axios.interceptors.request.eject(reqInterceptor);
  //     axios.interceptors.response.eject(resInterceptor);
  //   };
  // }, [interceptors]);

  return <AxiosContext.Provider value={axios}>{children}
  </AxiosContext.Provider>;
}

export default AxiosProvider;
