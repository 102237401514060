import { QueryClient, QueryClientProvider } from "react-query";
import React, { BrowserRouter, Link } from "react-router-dom";
import Router from "./Router";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import AuthProvider from "./components/providers/AuthProvider";
import AxiosProvider from "./components/providers/AxiosProvider";
import GlobalProvider from "./components/providers/GlobalProvider";

import "dayjs/locale/ja";
// import utc from "dayjs/plugin/utc";
// import timezone from "dayjs/plugin/timezone";

import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useGlobalState } from "./components/hooks/useGlobalState";

const queryClient = new QueryClient();

// DayJS
dayjs.locale("ja");
// dayjs.extend(utc);
// dayjs.extend(timezone);

function App() {
  const { globalState, setGlobalState } = useGlobalState();
  const [height, setHeight] = useState(800);

  useEffect(() => {
    setHeight(window.innerHeight);
  }, []);

  return (
    <GlobalProvider>
      <BrowserRouter>
        <AuthProvider>
          <AxiosProvider>
            <QueryClientProvider client={queryClient}>
              <Helmet>
                <meta
                  name="viewport"
                  content={`width=device-width, initial-scale=${
                    height >= 730 ? 1 : 0.7
                  }, maximum-scale=1`}
                />
              </Helmet>
              <div style={{ height: "100vh" }}>
                <Router />
              </div>
            </QueryClientProvider>
          </AxiosProvider>
        </AuthProvider>
      </BrowserRouter>
    </GlobalProvider>
  );
}

export default App;
