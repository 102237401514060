import dayjs, { Dayjs } from "dayjs";
import { useContext, useEffect, useRef, useState } from "react";
import { UserGuidanceControllerApiFp } from "../../api/generated/v1.0/app";
import { QUERY_KEYS } from "../../constants/queries";
import { message } from "../../utils/message";
import { GlobalContext } from "../providers/GlobalProvider";
import { useAuth } from "./useAuth";
import { useAxios } from "./useAxios";
import { useQueryWithRefresh } from "./useQueryWithRefresh";

export const useGlobalState = () => {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error("GlobalContext must be within GlobalProvider");
  }

  return context;
};
